var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "voffice-popup-box",
    {
      staticClass: "full-xxs",
      attrs: { "trigger-id": _vm.triggerId, "apply-label": _vm.applyLabel },
      on: { closed: _vm.onClosed }
    },
    [
      _c(
        "table",
        { staticClass: "guestbox", attrs: { "aria-live": "polite" } },
        [
          _c("thead", { staticClass: "sr-only" }, [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.voMsg("tpl.label")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.voMsg("tpl.decrement")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.voMsg("tpl.increment")))])
            ])
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.sections, function(l, what) {
                return _c("tr", { staticClass: "gb-row" }, [
                  _c("td", { staticClass: "gb-td label" }, [
                    _c("label", [_vm._v(_vm._s(_vm.getFieldLabel(what)))]),
                    _vm._v(" "),
                    what === "adults"
                      ? _c("div", { staticClass: "age" }, [
                          _vm._v(_vm._s(_vm.voMsg("tpl.adults.age")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    what === "children"
                      ? _c("div", { staticClass: "age" }, [
                          _vm._v(_vm._s(_vm.voMsg("tpl.children.age")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    what === "babys"
                      ? _c("div", { staticClass: "age" }, [
                          _vm._v(_vm._s(_vm.voMsg("tpl.baby.age")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "gb-td" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { disabled: !_vm.canDecr(what), type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.inc(what, -1)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa-light fa-circle-minus" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v(_vm._s(_vm.labelMinus(what)))
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "gb-td" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { disabled: !_vm.canInc(what), type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.inc(what, 1)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa-light fa-circle-plus" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v(_vm._s(_vm.labelPlus(what)))
                        ])
                      ]
                    )
                  ])
                ])
              }),
              _vm._v(" "),
              _vm.maxbel
                ? _c("tr", [
                    _c(
                      "td",
                      { staticClass: "max-bel", attrs: { colspan: "3" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "alert alert-danger",
                            attrs: { role: "alert" }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.voMsg("tpl.text.maxOccupancyReached")
                                ) +
                                "\n                    "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }