export default {
	data: {
		selectedImageIndex: undefined,
		openstreetmap: false,
		showFullSeoText: false,
		showAllPartnerLogos: false
	},
	beforeMount: function () {

		this.checkOsmConsent();

		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	mounted: function () {
		window.addEventListener('ccm19WidgetClosed', (event) => {
			this.checkOsmConsent();
		});
	},


	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['website', 'v-office.com']).join('@');
		},

		phoneView: function () {
			if (VOFFICE.phoneView) {
				return VOFFICE.phoneView;
			}
		},

	},
	methods: {

		phoneLink: function (phonelink) {
			var el = document.getElementById('preloader');
			var phonenumber;
			if (phonelink) {
				phonenumber = phonelink;
			} else if (VOFFICE.phoneLink) {
				phonenumber = VOFFICE.phoneLink;
			}
			if (phonenumber) {
				window.location.href = phonenumber;
				if (el) {
					el.style.display = "none";
				}
			}
		},
		checkOsmConsent: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			const allowOpenstreet = this.getCookie(cookieName);
			if (allowOpenstreet) {
				this.openstreetmap = true;
			} else {
				const data = CCM.acceptedEmbeddings;
				const targetName = "OpenStreetMap";
				const entry = data.find(item => item.name === targetName);


				if (entry) {
					this.openstreetmap = true;
				} else {
					this.openstreetmap = false;
				}
			}
		},

		getCookie: function (name) {
			const nameEQ = name + "=";
			const ca = document.cookie.split(';');
			for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) === ' ') c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
			}
			return null;
		},

		setCookie: function (name, value, days) {
			const date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			const expires = "expires=" + date.toUTCString();
			document.cookie = name + "=" + value + ";" + expires + ";path=/";
		},

		allowOpenstreetmap: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			this.setCookie(cookieName, true, VOFFICE.settings.osmCookieDays);
			this.openstreetmap = true;
		},

		showOpenstreetmap: function () {
			this.openstreetmap = true;
		},

		unitImages: function (string) {
			//console.log(string);
		},

		closeVideoPanel: function () {
			var d = new Date();
			d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000)); //3 days
			var expires = "expires=" + d.toUTCString();
			document.cookie = "mvvideo=true" + ";" + expires + ";path=/";
			if (document.getElementById('videoButtonWithImage')) {
				document.getElementById('videoButtonWithImage').className = 'd-none';
				document.getElementById('video-panel-close-btn').className = 'd-none';
				document.getElementById('videoButton').className = '';
			}
		},
		isVideoPlayed: function () {
			var name = "mvvideo=";
			//console.log('document.cookie::' + document.cookie);
			var ca = document.cookie.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1);
				}
				//console.log('c::::' + c);
				if (c.indexOf(name) == 0) {
					return true;
				}

			}
			return false;
		}
	},

};