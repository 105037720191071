import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
		showSearchBox: false,
		showTeleBox: false,
		showSettings: false,
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		},

		phoneView: function () {
			if (VOFFICE.phoneView) {
				return VOFFICE.phoneView;
			}
		},

	},

	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

	},

	methods: {
		openDropdown: function (id) {
			if (window.innerWidth > 993) {
				const elements2 = document.querySelectorAll('.dropdown-toggle, .dropdown-menu');

				// Iteriere über alle selektierten Elemente
				elements2.forEach(element => {
					// Entferne die Klasse 'show'
					element.classList.remove('show');
				});

				const elements = document.querySelectorAll('.' + id);
				elements.forEach(element => {
					// Füge die Klasse 'show' hinzu
					element.classList.add('show');
				});
			}
		},
		closeDropdown: function (id) {
			if (window.innerWidth > 993) {

				const elements = document.querySelectorAll('.' + id);
				elements.forEach(element => {
					// Füge die Klasse 'show' hinzu
					element.classList.remove('show');
				});
			}
		},
		scrollToTop: function () {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},
		toggleSearchbox: function (event) {
			this.showSearchBox = !this.showSearchBox;
			var inputfeld = document.getElementById("objSearch");
			var btn = document.getElementById("objSearchBtn");
			if (this.showSearchBox) {
				this.showTeleBox = false;
				this.showSettings = false;
				btn.tabIndex = 0;
				inputfeld.focus();
			} else {
				inputfeld.blur();
				btn.tabIndex = -1;
			}
		},
		toggleTelebox: function (event) {
			this.showTeleBox = !this.showTeleBox;
			var telebox = document.getElementById("tele-box");
			var phoneLink = document.getElementById("phoneLink");
			if (this.showTeleBox) {
				telebox.focus();
				phoneLink.tabIndex = 0;
				this.showSearchBox = false;
				this.showSettings = false;
			} else {
				telebox.blur();
				phoneLink.tabIndex = -1;
			}
		},
		toggleSettings: function (event) {
			this.showSettings = !this.showSettings;
			var settings = document.getElementById("settings");
			var contrastLink = document.getElementById("contrastLink");
			var typeLink = document.getElementById("typeLink");
			if (this.showSettings) {
				settings.focus();
				contrastLink.tabIndex = 0;
				typeLink.tabIndex = 0;
				this.showSearchBox = false;
				this.showTeleBox = false;
			} else {
				settings.blur();
				contrastLink.tabIndex = -1;
				typeLink.tabIndex = -1;
			}
		},
		phoneLink: function (phonelink) {
			var el = document.getElementById('preloader');
			var phonenumber;
			if (phonelink) {
				phonenumber = phonelink;
			} else if (VOFFICE.phoneLink) {
				phonenumber = VOFFICE.phoneLink;
			}
			if (phonenumber) {
				window.location.href = phonenumber;
				if (el) {
					el.style.display = "none";
				}
			}
		},

		navbarToggle: function () {


			$('.navbar-v1').removeClass('open-by-mob');

			this.showTeleBox = false;
			this.showSearchBox = false;

		},

		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}



	}
};