<template>
    <div class="dlg"
        ref="dlg"
        :class="{show:showing}">

        <div class="box layout-column">

            <div class="header">

                <div class="popup-header">{{headerLabel}}</div>
                <div class="header-close"
                    v-show="headerClose"
                    @click="onCloseBtnClick">
                    x
                </div>

            </div>
            <div class="body flex">
                <slot></slot>
            </div>
            <div class="footer"
                v-show="applyLabel">
                <button type="button"
                    @click="onCloseBtnClick"
                    class="btn btn-primary">{{applyLabel}}</button>
            </div>

        </div>
    </div>
</template>

<script>
    import V from 'voUtils/V.js'

    export default {
    	voVueComponent: 'voffice-popup-dlg',
    	props: {
    		triggerId: String,
    		applyLabel: String,
    		headerLabel: String,
    		headerClose: Boolean,
    		youtubeVideo: Boolean,
    		videoId: String,
    		src: String,
    	},
    	data: function() {
    		return {
    			showing: false,
    			hasBeenShown: false,

    		}
    	},

    	mounted: function() {
    		this.triggerEl = document.getElementById(this.triggerId);
    		if (this.triggerEl) {
    			this.triggerEl.addEventListener('click', this.toggleShow);
    		}

    		this.$refs.dlg.addEventListener('click', (e) => {
    			if (e.target.isSameNode(this.$refs.dlg)) {
    				this.hide();
    			}
    		});

    	},
    	methods: {
    		onCloseBtnClick: function() {
    			this.hide();
    			this.stopVideo();
    		},
    		show: function() {
    			this.showing = true;
    			if (this.videoId) {
    				this.setVideoSrc();
    			}
    		},
    		setVideoSrc: function() {
    			this.src = "https://www.youtube.com/embed/FWR_fi-Flqs";
    		},
    		hide: function() {
    			this.showing = false;
    			this.stopVideo();
    		},
    		toggleShow: function(event) {
    			if (!this.showing) {
    				this.startVideo();
    			}
    			this.showing = !this.showing;
    		},
    		stopVideo: function() {
    			//console.log('this.isYoutubeVideo::' + this.youtubeVideo);
    			//console.log('this.videoId::' + this.videoId);
    			if (this.youtubeVideo) {
    				let video = document.getElementById(this.videoId);
    				if (video) {
    					//console.log('assigning new video');
    					video.src = "https://www.youtube.com/embed/FWR_fi-Flqs"
    						//video.contentWindow.postMessage('{"event":"command", "func":"stopVideo", "args":""}', '*');
    						//video.contentWindow.postMessage( '{"event":"command", "func":"pauseVideo", "args":""}', '*');
    					var d = new Date();
    					d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000)); //3 days
    					var expires = "expires=" + d.toUTCString();
    					document.cookie = "mvvideo=true" + ";" + expires + ";path=/";
    					if (document.getElementById('videoButtonWithImage')) {
    						document.getElementById('videoButtonWithImage').className = 'd-none';
    						document.getElementById('videoButton').className = '';
    						document.getElementById('video-panel-close-btn').className = 'd-none';
    					}
    				}
    			}
    		},
    		startVideo: function() {
    			//console.log('this.isYoutubeVideo::' + this.youtubeVideo);
    			//console.log('this.videoId::' + this.videoId);
    			if (this.youtubeVideo) {
    				let video = document.getElementById(this.videoId);
    				if (video) {
    					//console.log('assigning new video');
    					video.src = "https://www.youtube.com/embed/FWR_fi-Flqs?autoplay=1"
    						//video.contentWindow.postMessage('{"event":"command", "func":"stopVideo", "args":""}', '*');
    						//video.contentWindow.postMessage( '{"event":"command", "func":"pauseVideo", "args":""}', '*');
    				}
    			}
    		}
    	},
    	watch: {
    		showing: function() {
    			if (this.showing) {
    				this.hasBeenShown = true;
    			} else if (this.hasBeenShown) {
    				this.$emit('closed');
    				this.stopVideo();
    			}

    		}
    	}
    }
</script>


<style scoped>
    .dlg {
    	padding: 20px;
    	display: none;
    	z-index: 999;
    	background: rgba(0, 0, 0, 0.5);
    	position: fixed !important;
    	left: 0px !important;
    	right: 0px !important;
    	bottom: 0px !important;
    	top: 0px !important;
    	margin: 0px !important;
    }

    .box {
    	position: relative;
    	width: 800px;
    	height: 800px;
    	max-height: 100%;
    	max-width: 100%;
    	background: #ffffff;
    	border: solid 1px #cccccc;
    	border-radius: 5px;
    	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    	left: auto;
    	margin-right: auto;
    	margin-left: auto;
    }

    .body {
    	padding: 22px 15px;
    	overflow: auto;
    	position: relative;
    	-webkit-overflow-scrolling: touch;

    }

    .footer {
    	border-top: 1px solid rgb(235, 235, 235);
    	padding: 12px 15px;
    	text-align: right;
    }

    .header {
    	border-bottom: 1px solid rgb(235, 235, 235);
    	padding: 6px 15px;
    	text-align: center;
    }

    .dlg.show {
    	display: block;
    }

    .header-close {
    	position: absolute;
    	font-size: 26px;
    	right: 15px;
    	font-family: arial, helvetica, sans-serif;
    	top: 0px;
    	cursor: pointer;
    	color: #777;
    }

    @media (max-width:767px) {

    	.dlg {
    		padding: 0;
    	}

    	.box {
    		width: 100%;
    		height: 100%;
    		border-radius: 0px;
    		border: none;
    	}

    	.header-close {
    		position: absolute;
    		font-size: 26px;
    		right: 15px;
    		font-family: arial, helvetica, sans-serif;
    		top: 0px;
    		cursor: pointer;
    		color: #777;
    	}

    }
</style>