import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: []
		};
	},

	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}
	},
	mounted: function () {
		this.observeVisibility();
	},
	updated: function () {
		this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		observeVisibility() {
				const options = {
					root: null,
					rootMargin: '-500px 0px 0px 0px',
					threshold: 0
				};

				const callback = (entries, observer) => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							this.getUnits();
							observer.unobserve(entry.target);
						}
					});
				};

				const observer = new IntersectionObserver(callback, options);
				observer.observe(document.getElementById('lastviewed-units'));
			},
			show: function () {
				if (this.units.length > 0) {
					return true;
				}
				return false;
			},
			getUnitSearchTarget: function (id) {
				return 'unit_' + id;
			},

			getUnits: function () {
				var suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
				var savedUnits = JSON.parse(localStorage.getItem('lastVisitedUnits_' + suffix));
				var count = 0;

				if (savedUnits) {
					count = Object.keys(savedUnits).length;
				}

				var unitsIds = [];
				if (count > 0) {
					for (var index = 0; index < count; index++) {
						unitsIds.push(savedUnits[index]._id);
					}

					var req = {
						fields: this.resultFields,
						filter: {

						},
						ids: unitsIds,
						pricelist: true
					};

					doReq('searchUnits', req)
						.then((res) => {
							this.units = res.units;

						});
				}

			},

			applySlider: function () {
				$(".owl-carousel.lastviewed-units").owlCarousel({
					loop: false,
					margin: VOFFICE.settings.olwCarouselMargin,
					smartSpeed: 1000,
					stagePadding: 10,
					autoplay: false,
					nav: VOFFICE.settings.olwCarouselNav,
					dots: true,
					responsive: {
						0: {
							items: 1
						},
						768: {
							items: 2
						},
						996: {
							items: 3
						},
						1400: {
							items: 4

						}
					}
				});
			}
	}

};